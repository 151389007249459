<template>
  <div class="clients-list">
    <UiBanner fluid :title="$t('clients.list.name')" :breadcrumbs="breadcrumbs">
      <template v-slot:actions>
        <div class="tw-flex tw-items-center">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('clients.list.search.label')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />

          <ui-list-filters @validate="getClientFiltered">
            <template v-slot:content>
              <v-list class="clients-list__filters">
                <v-list-item>
                  <ui-block class="tw-w-full" id="featuresLabel" :label="$t('clients.list.filters.features.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        id="features"
                        v-model="filters.features"
                        solo
                        flat
                        outlined
                        dense
                        clearable
                        item-value="slug"
                        item-text="name"
                        :items="features"
                        :placeholder="$t('clients.list.filters.features.placeholder')"
                      />
                    </template>
                  </ui-block>
                </v-list-item>

                <v-list-item>
                  <ui-block class="tw-w-full" id="categoryLabel" :label="$t('clients.list.filters.category.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        id="category"
                        v-model="filters.category"
                        solo
                        flat
                        outlined
                        dense
                        clearable
                        item-value="value"
                        item-text="label"
                        :items="categories"
                        :placeholder="$t('clients.list.filters.category.placeholder')"
                      />
                    </template>
                  </ui-block>
                </v-list-item>
              </v-list>
            </template>
          </ui-list-filters>

          <v-btn class="tw-ml-4 tw-rounded-md" color="primary" @click="goToCreate">
            {{ $t('clients.list.search.button') }}
          </v-btn>
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="clients-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="clients-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :loading="refreshing"
          :headers="headers"
          :items="clients"
          :options.sync="options"
          :search="search"
          :footer-props="{ itemsPerPageOptions: [1000] }"
          @click:row="goToClientsId"
        >
          <template v-slot:[`item.state`]="{ item }">
            <v-icon v-if="item.state === true" color="success">{{ icons.mdiCheckCircle }}</v-icon>
            <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
          </template>

          <template v-slot:[`item.multiMarketStatus`]="{ item }">
            <v-chip dark color="#449afd" small label>
              <v-icon size="20" left color="white">
                {{ item.multiMarketStatus === true ? icons.mdiCheckCircle : icons.mdiCloseCircle }}
              </v-icon>
              {{ $tc('clients.list.table.multiMarket', item.markets.length, { count: item.markets.length }) }}
            </v-chip>
          </template>

          <template v-slot:[`item.defaultLocaleIsoCode`]="{ item }">
            <div v-if="item.defaultLocaleIsoCode" class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div v-if="getSvgBy('lang', item.defaultLocaleIsoCode)">
                <v-img width="25" :src="getSvgBy('lang', item.defaultLocaleIsoCode)" />
              </div>
              <span>{{ getLangByCode(item.defaultLocaleIsoCode) }}</span>
            </div>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.type.name }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-icon size="24" left :color="item.active ? 'success' : 'error'">
              {{ item.active === true ? icons.mdiCheckCircle : icons.mdiCloseCircle }}
            </v-icon>
          </template>

          <template v-slot:[`item.features`]="{ item }">
            <div v-for="(featureIdentifier, index) in item.features" :key="`feature ${index}`">
              {{ getFeatureName(featureIdentifier) }}
            </div>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'

import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiListFilters from '@/components/UI/List/Filters.vue'

import { debounce } from '@/utils/debounce.util'
import { getSvgBy } from '@/utils/flag.util'
import { getLangByCode } from '@/utils/languages.util'

export default {
  name: 'ClientsList',
  components: {
    UiBanner,
    UiContainer,
    UiBlock,
    UiListFilters,
  },
  data: () => ({
    getSvgBy,
    getLangByCode,
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    refreshing: false,
    search: '',
    options: {
      size: 1000,
    },
    filters: {
      features: null,
      category: null,
    },
  }),
  async created() {
    this.refreshing = true
    await Promise.all([this.getFeatures(), this.loadClients()])
    this.refreshing = false
  },
  computed: {
    ...mapState({
      clients: state => state.client.clients,

      features: state => state.setting.features,
    }),
    categories() {
      return [
        {
          label: this.$t('clients.list.filters.category.options.client'),
          value: 'client',
        },
        {
          label: this.$t('clients.list.filters.category.options.prospect'),
          value: 'prospect',
        },
        {
          label: this.$t('clients.list.filters.category.options.internal'),
          value: 'internal',
        },
      ]
    },
    breadcrumbs() {
      return [
        {
          text: this.$t('clients.list.name'),
          to: {
            name: 'ClientsList',
          },
        },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('clients.list.headers.name'),
          value: 'name',
          sortable: true,
          width: '24%',
        },
        {
          text: this.$t('clients.list.headers.multiMarketStatus'),
          value: 'multiMarketStatus',
          sortable: true,
        },
        {
          text: this.$t('clients.list.headers.defaultLocaleIsoCode'),
          value: 'defaultLocaleIsoCode',
          sortable: true,
        },
        {
          text: this.$t('clients.list.headers.category'),
          value: 'category',
          sortable: true,
        },
        {
          text: this.$t('clients.list.headers.type'),
          value: 'type_name',
          sortable: true,
        },
        {
          text: this.$t('clients.list.headers.features'),
          value: 'features',
          sortable: false,
        },
        {
          text: this.$t('clients.list.headers.status'),
          value: 'state',
          sortable: true,
        },
        {
          text: this.$t('clients.list.headers.active'),
          value: 'active',
          sortable: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getClientsCore: 'client/getClientsCore',

      getFeatures: 'setting/getFeatures',
    }),
    getFeatureName(identifier) {
      return this.features.find(feature => feature.identifier === identifier)?.name || ''
    },
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      }
    }, 300),
    goToCreate() {
      this.$router.push({ name: 'ClientCreate' })
    },
    goToClientsId(client) {
      this.$router.push({ name: 'ClientsDashboard', params: { id: client.uuid } })
    },
    async getClientFiltered() {
      this.refreshing = true
      try {
        const { features, category } = this.filters
        const query = []

        if (features) {
          query.push({ key: 'features.slug', value: features })
        }

        if (category) {
          query.push({ key: 'category', value: category })
        }

        await this.getClientsCore({ query, options: this.options })
      } catch (error) {
        // error
      }
      this.refreshing = false
    },
    async loadClients() {
      this.refreshing = true
      await this.getClientsCore({ options: this.options })
      this.refreshing = false
    },
  },
}
</script>

<style lang="scss" scoped>
.clients-list {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
