<template>
  <div class="ui-list-filters">
    <v-btn class="tw-ml-4" @click="showFilter = !showFilter" color="#449afd" rounded elevation="0" outlined>
      <v-icon>{{ icons.mdiFilterOutline }}</v-icon>
      {{ $t('button.filter') }}
    </v-btn>

    <v-navigation-drawer width="350px" v-model="showFilter" absolute right temporary>
      <v-subheader class="ui-list-filters__title">{{ $t('button.filter') }}</v-subheader>
      <v-divider />

      <div class="ui-list-filters__content">
        <slot name="content" />
      </div>

      <template v-slot:append>
        <v-divider />
        <v-btn @click="validate" class="tw-flex tw-mx-auto tw-my-6" color="#449afd" outlined>
          {{ $t('button.validate') }}
        </v-btn>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mdiFilterOutline } from '@mdi/js'

export default {
  name: 'UiListFilters',
  data() {
    return {
      icons: {
        mdiFilterOutline,
      },
      showFilter: false,
    }
  },
  methods: {
    validate() {
      this.showFilter = false
      this.$emit('validate')
    },
  },
}
</script>

<style lang="scss">
.ui-list-filters {
  .v-navigation-drawer__content {
    height: auto;
  }
}
</style>

<style scoped lang="scss">
.ui-list-filters {
  &__title {
    @apply tw-text-base tw-font-medium;
    color: $blue-dodger;
  }

  &__content {
    @apply tw-px-4 tw-pt-6 tw-pb-4;
  }
}
</style>
