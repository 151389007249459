<template>
  <label :for="id" class="ui-label tw-text-xs tw-text-gray-500 dark:tw-text-gray-400 tw-font-medium tw-inline-flex">
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'UiLabel',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
